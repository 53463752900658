/* ModalStyles.css
.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    z-index: 1000;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    height: auto;
    min-height: 100px; 
    max-height: 90%; 
    overflow-y: auto; 
  }
  .modal-header,
  .modal-footer {
    padding: 10px;
    display: flex;
    justify-content: space-between; 
    align-items: center; 
    height: 20px;
  }
  .modal-header {
    padding: 10px;
    display: flex;
    justify-content: space-between; 
    align-items: center;
  }
  .header-content {
    flex-grow: 1;
    display: flex;
    justify-content: center; 
    align-items: center; 
  }
  
  .modal-content {
    padding: 10px;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 500;
  }
  
  .close-button {
    cursor: pointer; 
    border: none;
    background: none;
    font-size: 1.5rem;
    font-weight: bold;
    margin-left: auto; 
  }
  
  .close-button:hover, .close-icon:hover {
    cursor: pointer; 
  }
  .close-icon {
    cursor: pointer; 
    font-size: 1.5rem; 
  }
  
  .close-icon:hover {
    opacity: 0.8; 
  }
  .small {
    width: 300px;
  }
  
  .medium {
    width: 500px;
  }
  
  .large {
    width: 800px;
  }
  
  .extra-large {
    width: 1100px;
  }
  
  .full-screen {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
   */
/* Genel overlay stil ayarları */
/* Overlay Stili */
/* Overlay stil ayarları */
/* Overlay Stili */
.overlay1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

/* Modal Genel Stili */
.modal1 {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1002;
  transition: all 0.3s ease;
  max-height: 100vh !important;
  max-width: 95vw;
  height: auto;
}

/* Modal Açılış ve Kapanış Animasyonları */
.modal1.open {
  opacity: 1;
  visibility: visible;
}

.modal1.close {
  opacity: 0;
  visibility: hidden;
}

/* Header ve Footer Stilleri */
.modal-header1,
.modal-footer1 {
  padding: 16px;
  background-color: #f1f1f1;
  /* border-bottom: 1px solid #ddd; */
  /* Sadece header için */
  color: #fff;
}

.modal-header1 {
  background-color: #255588 !important;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.modal-footer1 {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top: none;
  background-color: #ffffff;
  /* Footer'ın üst kenar çizgisini kaldır */
}

/* İçerik Stili */
.modal-content1 {
  padding: 10px;
  overflow: hidden;
  overflow-y: auto;
  height: calc(100% - 200px);

}
.modal-conte  {
  /* overflow-y: scroll; */
  max-height: 80vh;
  background-color: #f4f4f4;
}
/* Close İkonu Stili */
.close-icon {
  cursor: pointer;
  float: right;
}

/* Boyut Sınıfları */
.modal1.small {
  width: 300px;
}

.modal1.medium {
  width: 500px;
}

.modal1.large {
  width: 800px;
}

.modal1.extra-large {
  width: 95%;
  max-width: 1140px;
}

.modal1.full-screen {
  width: 100%;

}

/* Header ve Footer içeriğini ortala */
.header-content1,
.modal-footer1 {
  display: flex;
  justify-content: end;
  align-items: center;
}