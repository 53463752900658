/* Content.css */
.update-card {
    margin: 15px 0;
    padding: 20px;
  }
  
  .update-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .update-container .card {
    flex: 1 1 calc(33% - 20px);
    max-width: 300px;
  }
  
  .logo {
    max-width: 300px;
    margin: 0 auto;
    background-color: transparent;
  }
  
  .text-center img {
    margin-bottom: 20px;
  }
  