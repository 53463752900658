@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
* {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: 300;
}
.r-fs-16 {
  font-family: "Nunito", sans-serif;
  font-size: 11px !important;
  margin: 1px;
}
.r-letter-spacing-1 {
  letter-spacing: 1px;
}
.r-shadow-sm {
  // box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) !important;
  box-shadow: 6px 6px 0px 0px #255588 !important;
  border: 1px solid #255588 !important;
  border-radius: 0.375rem !important;
}
.w-15 {
  width: 13.6% !important;
}
.w-40 {
  width: 40% !important;
}
.w-60 {
  width: 60% !important;
}
.w-47 {
  width: 47% !important;
}
.w-10 {
  width: 10% !important;
}
.w-20 {
  width: 20% !important;
}
.w-30 {
  width: 30% !important;
}
.w-70 {
  width: 70% !important;
}
.w-35 {
  width: 35% !important;
}
.w-10 {
  width: 10% !important;
}
.w-5 {
  width: 5% !important;
}
.w-15 {
  width: 15% !important;
}

.pr-0 {
  padding-right: 0 !important;
}
.text-mavi {
  color: #008ecc !important;
}
.menu-text-color-black:hover {
  color: #c0392b !important;
}
/* .menu-icon-color-gray {
  color: #c0392b !important;
} */
.menu-text-color-black {
  color: white !important;
}
.App {
  text-align: center;
  height: 100vh !important;
  width: 100vw !important;
  position: relative;
  overflow: hidden; /* Yeni eklendi */
}

// .App-header {
//   background-color: #282c34;
//   height: 10% !important;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

.App-header {
  background-image: linear-gradient(to bottom, #282c34, #2c3e54);
  height: 4% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgb(252, 2, 2) !important;
}

.App-body {
  display: flex;
  flex-direction: row;
  position: absolute;
  height: 92%;
  width: 100%;
}

.App-sidebar {
  height: 100% !important;
  width: 0;
  background-color: #2c3e54;
  /* overflow-x: hidden; */
  transition: 0.5s;
  padding-bottom: 60px !important;
}

.App-sidebar.closed {
  .menu1 {
    display: none;
  }
}

.App-sidebar.open {
  width: calc(15% + 10px); /* Scrollbar genişliğini hesaba kat */
  height: 100% !important;
}

.App-content {
  height: 100% !important;
  flex-grow: 1;
  padding: 10px 10px 10px 40px;
  transition: 0.5s;
  background-color: #fff;
}

.App-content.open {
  width: 85%;
  background-color: #fff;
}
.footer-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 4%;
  /* background-color: #282c34; */
  background: linear-gradient(to bottom, #7f8c8d, #4d5557);
  color: white;
  box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.3);
}
/* info */
.info-button {
  cursor: grab;
  position: fixed;
  right: 10px;
  top: 10%; /* Başlangıç konumu */
  z-index: 100;
  background-color: #007bff;
  color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-sidebar {
  position: fixed;
  right: -40%;
  top: 0;
  width: 40%;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.5);
  transition: right 0.3s;
  z-index: 1001;
  padding: 20px;
}

.info-sidebar.open {
  right: 0;
}
/* info */
@media screen and (max-width: 568px) {
  .App-sidebar {
    width: 0;
  }

  .App-sidebar.open {
    width: calc(80% + 10px); /* Scrollbar genişliğini hesaba kat */
  }

  .App-content.open {
    width: 20%;
  }
}
/* Küçük ekranlar için (genellikle mobil cihazlar) */
@media screen and (max-width: 768px) {
  .App-sidebar {
    /* Mobil cihazlarda varsayılan olarak sidebar'ı gizle */
    width: 0;
  }

  .App-sidebar.open {
    /* Sidebar açıkken genişliği ayarla */
    width: calc(60% + 10px); /* Scrollbar genişliğini hesaba kat */
  }

  .App-content.open {
    /* Sidebar açıkken içerik alanının genişliğini azalt */
    width: 40%; /* Sidebar açıkken kalan genişlik */
  }
}
/* Orta boy ekranlar için (genellikle tabletler) */
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .App-sidebar {
    /* Tablet cihazlarda varsayılan olarak sidebar'ı biraz göster */
    width: 0;
  }

  .App-sidebar.open {
    /* Sidebar açıkken genişliği ayarla */
    width: 30%; /* Tablet cihazlarda genişlik */
  }

  .App-content {
    /* Sidebar açıkken içerik alanının genişliğini azalt */
    width: 70%; /* Sidebar açıkken kalan genişlik */
  }
}

/*  buton cssi */
/* Butonun normal hali */
.r-buton-success {
  background-color: #588157 !important; /* Mavi */
  color: white !important; /* Beyaz yazı rengi */
  border-radius: 5px; /* Köşeleri yuvarlat */
  transition: background-color 0.2s ease-in-out !important; /* Arka plan rengi değişiminde animasyon */
}
.r-buton-danger {
  background-color: #e02d39 !important; /* Kırmızı */
  color: white !important; /* Beyaz yazı rengi */
  border-radius: 5px; /* Köşeleri yuvarlat */
  transition: background-color 0.2s ease-in-out !important; /* Arka plan rengi değişiminde animasyon */
}
.r-buton-orange {
  background-color: #f39c12 !important; /* Kırmızı */
  color: white !important; /* Beyaz yazı rengi */
  border-radius: 5px; /* Köşeleri yuvarlat */
  transition: background-color 0.2s ease-in-out !important; /* Arka plan rengi değişiminde animasyon */
}
.r-buton-danger:hover {
  background-color: #e02d3999 !important; /* Kırmızı */
}
/* Butonun hover durumu */
.r-buton-success:hover {
  background-color: #789977 !important; /* Kırmızı */
}
.r-buton-orange:hover {
  background-color: #f39b20d0 !important; /* Kırmızı */
}
/* buton cssi */
.r-input-sm {
  padding-block: 0 !important;
  height: 20px !important;
  border: 1px solid #255588 !important;
}
// .k-input-md .k-input-inner, .k-picker-md .k-input-inner {
// border : 1px solid #255588 !important;
// border-bottom-left-radius: 4px;
// border-top-left-radius: 4px;
// }
// .k-input{
//   border : 1px solid #255588 !important;

// }

/* tab panel renklendirme */
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #fff !important;
  background: linear-gradient(
    45deg,
    #0d47a1,
    #1976d2,
    #378ace,
    #3b95df
  ) !important;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.nav-tabs .nav-link {
  color: #222 !important;
  background-color: #d0d0d0 !important;
  border: 1px solid #dee2e6 !important;
  border-bottom-color: transparent !important;
}
.info-panel {
  border: 1px solid #255588;
  border-radius: 5px;
  padding: 10px;
}

.info-panel__title {
  font-weight: bold;
  margin-bottom: 10px;
}

.info-panel__content {
  display: flex;
  flex-direction: column;
}

.info-panel__item {
  margin-bottom: 5px;
  padding: 5px;
}
/* tab panel renklendirme */

/* checkbox  renk*/
// .k-radio:checked, .k-radio.k-checked {
//   background-color: #255588 !important;
//   border-color: #255588 !important;
// }
// .k-radio:checked:focus, .k-radio.k-checked.k-focus {
//   background-color: #255588 !important;
//   border-color: #255588 !important;
//   box-shadow: 0 0 0 1px #2555882d !important;
// }
// .k-radio {
// border-color: #255588 !important;
// }
/* checkbox  renk*/
/* dropdown border color*/
// .k-picker-solid {
//   border-color: #255588 !important;
// }
// /* Kendo DropDownList item'larının arkaplan rengini özelleştir */
// .k-dropdown .k-list .k-item:hover, .k-dropdown .k-list .k-item.k-state-focused, .k-dropdown .k-list .k-item.k-state-selected {
//   background-color: #255588 !important; /* Özel turuncu renginiz */
//   color: white !important; /* Metin rengi değişikliği */
// }

// .k-popup .k-item.k-state-selected {
//   background-color: #4CAF50 !important;
//   color: white !important;
// }
// .k-var--selected-background {
//   background-color: #255588 !important;
// }
// .k-list-item.k-selected, .k-selected.k-list-optionlabel {
//   background-color: #255588 !important;
//   color: white !important;
// }
.b-left-white {
  border-left: 1px solid white !important;
}
.d-buton-hover:hover {
  background-color: #255588 !important;
}
.table {
  --bs-table-hover-bg: #255588 !important;
  font-weight: bold;
  --bs-table-hover-color: #fff !important ;
  cursor: pointer;
}
.table td {
  text-align: left;
}
.accordion {
  --bs-accordion-active-bg: #e02d39 !important;
  --bs-accordion-active-color: #fff !important;
  --bs-accordion-btn-focus-border-color: #255588 !important;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
  --bs-accordion-border-color: #255588 !important;
  letter-spacing: 1px;
}
.accordion-button:hover {
  // --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
  background-color: #255588 !important;
  --bs-accordion-color: #fff !important;
  --bs-accordion-btn-color: #fff !important;
  --bs-accordion-btn-focus-border-color: #255588 !important;
  font-weight: bold;
  letter-spacing: 1px;
  --bs-accordion-btn-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
}

/* Aktif accordion başlığı için özel CSS sınıfı */
.accordion-header-bold .accordion-button:not(.collapsed) {
  font-weight: bold;
  letter-spacing: 1px;
}
// .k-grid .k-table-row {
//   cursor: pointer;
//   letter-spacing: 0.5px;
// }
// .k-grid .k-table-tbody > .k-table-row:not(.k-detail-row):hover, .k-grid .k-table-tbody > .k-table-row:not(.k-detail-row).k-hover{
//   background-color: #255588 !important;
//   color: white !important;
//   font-weight: bold;
//   letter-spacing: 0.5px;

// }
.k-grid .k-cell-inner > .k-link {
  display: flex;
  justify-content: center !important;
  height: 25px !important;
}
// .k-grid-content{
//   overflow: auto !important;
// }
// .k-grid-header, .k-grid-footer {
//   padding-inline-start: 0;
//   padding-inline-end: var(--kendo-scrollbar-width, 0) !important;
// }
.loader {
  border: 6px solid #f3f3f3; /* Light grey */
  border-top: 6px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#style-13::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.9);
  border-radius: 10px;
  background-color: #255588;
}
/* Firefox için kaydırma çubuğu stilleri */
#style-13 {
  scrollbar-color: #e02d39 #255588; /* Kaydırma çubuğu başparmağı ve izinin renkleri */
  scrollbar-width: thin; /* Firefox bu özelliği doğrudan desteklemez, bu yüzden 'thin', 'auto', veya 'none' kullanılabilir */
}

.k-animation-container {
  z-index: 10000 !important;
}
// .k-checkbox:checked, .k-checkbox.k-checked {
//   background-color: #588157  !important;
//   border-color: #588157  !important;
// }
// .k-checkbox{
//   border-color:#255588 !important;
// }
/* Genel CSS, tüm tarayıcılar için */
#style-13::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.9);
  border-radius: 10px;
  //transaparan bacground color
  background-color: rgba(37, 85, 136, 0.1) !important;
  height: 100%;
}

#style-13::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

#style-13::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #e1e0ff;
  /* Firefox bu gradient efektini doğrudan desteklemez, bu yüzden bu kısmı çıkarıyoruz */
}

.card-header {
  background: linear-gradient(
    rgb(125, 182, 234),
    /* Daha açık ton */ rgb(117, 153, 187) /* Daha açık ton */
  );

  
  color: white;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  // text-shadow: 
  //   1px 1px 2px rgba(255, 255, 255, 0.5);
}




.card-title {
  font-weight: bold;
  letter-spacing: 1px;
  margin: 0 !important;
  padding: 5px !important;
  font-size: 10px !important;
  color: white !important;
}
// .card{
//   border: 1px solid #255588 !important;
// }
.card-body {
  background-color: rgba(175, 232, 284, 0.1);
}

.form-control {
  padding-block: 0 !important;
  height: 25px !important;
  border: 1px solid #255588 !important;
}
.k-grid.k-grid-md .k-table-tbody .k-master-row.k-table-row .text-align-center {
  text-align: center !important;
}
.k-grid.k-grid-md .k-table .k-table-tbody .k-table-row.k-master-row:hover {
  cursor: pointer;
  td {
    color: white;
  }
}
.green-background {
}
style attribute {
  --kendo-scrollbar-width: 0 !important;
  --kendo-scrollbar-background: #255588 !important;
}
.k-grid.k-grid-md .k-table-tbody .k-master-row.k-table-row td.k-first-child,
.k-grid.k-grid-md .k-table-tbody .k-master-row.k-table-row td {
  font-size: 11px !important; /* Veya istediğiniz boyut */
}
.k-grid .k-grid-md td,
.k-grid .k-grid-md .k-table-td,
.k-grid-md td,
.k-grid-md .k-table-td {
  padding-block: 0.2rem !important;
  padding-inline: 0.5rem;
}
//--bs-nav-link-padding-y buna değer vermke istiyorum nasıl vericem

.nav-link {
  padding: 1px 10px !important;
}

.k-grid .k-table-tbody > .k-table-row:not(.k-detail-row):hover,
.k-grid .k-table-tbody > .k-table-row:not(.k-detail-row).k-hover {
  background-color: #255588 !important;
  color: white !important;
  font-weight: bold;
  letter-spacing: 0.5px;
}
.k-checkbox {
  border-color: rgb(0 0 0 / 40%);
  background-color: #ffffff;
}
.k-grid .k-cell-inner {
  background: linear-gradient(45deg, #b0bec5, #cfd8dc, #b0bec5) !important;
  color: black !important;
}

.k-grid-header .k-header {
  background: linear-gradient(45deg, #b0bec5, #cfd8dc, #b0bec5) !important;
  color: white !important;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.radio-container {
  border: 2px solid #AEDFF7; /* Açık mavi ton */
  background-color: #E0F7FA; /* Daha açık mavi ton, arka plan */
  padding: 10px;
  border-radius: 5px; /* Çerçevenin köşelerini yuvarlatır */
  display: inline-block; /* İstenirse, çerçevenin boyutunu içeriğe göre ayarlar */
}

.radio-container .ms-3 {
  margin-left: 3px;
}