

.date-picker-group {
    display: flex;
    align-items: center;
}

.date-picker-item {
    display: flex;
    align-items: center;
    margin-right: 20px;
}

.date-picker-item label {
    margin-right: 10px;
    width:140px !important;
}

.button-group {
    display: flex;
    align-items: center;
}

.btn {
    margin-right: 10px;
    display: flex;
    align-items: center;
}

.btn .k-button-icon {
    margin-right: 5px;
}
