.alert-container {
  position: fixed;
  bottom: 30px;
  right: 20px;
  display: flex;
  flex-direction: column-reverse; // Uyarıları dikey olarak yukarı doğru sırala
  align-items: flex-end;
  z-index: 1000;
}

.alert {
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px; // Uyarılar arası boşluk
  display: flex;
  align-items: center;
  gap: 10px;
  animation: slideIn 0.3s ease forwards;

  &.exit {
    animation: slideOut 0.3s ease forwards;
  }

  &-success { 
    background-color: rgb(237, 247, 237) !important;
    color: rgb(64,137,68) !important;
    .button:hover {
      background-color: rgb(64,137,68) !important;
    }
   }
  &-info { 
    background-color: rgb(229, 246, 253) !important;
    color: rgb(24,147,214) !important;
    .button:hover {
      background-color: rgb(24,147,214) !important;
    }
  }
  
  &-warning {
    background-color: rgb(255, 244, 229) !important;
    color: rgb(239,121,24) !important;
    
    .button:hover {
      background-color: rgb(239,121,24) !important;
    }
  }
  &-error { 
    background-color: rgb(253, 237, 237) !important;
    color: rgb(215,65,65) !important;

    .button:hover {
      background-color: rgb(215,65,65) !important;
    }
   }

  .icon {
    svg {
      width: 20px;
      height: 20px;
    }
  }

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    width: 25px;
    height: 25px;
    border-radius: 50px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}
