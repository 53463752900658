 
  /* Fırsat kartı stil ayarları */
  .firsat-karti {
    border: 1px solid #ccc; /* Çerçeve */
    border-radius: 8px; /* Köşe yuvarlaklığı */
    padding:7px 0 0 0px; /* İçerik ve çerçeve arasındaki boşluk */
    display: flex;
    flex-direction: column;
    gap: 5px; /* İçerikler arası boşluk */
    background-color: #255588; /* Arka plan rengi */
    color: white !important;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1); /* Kartın gölgesi */
    min-width: 5.29vw; /* Kartın minimum genişliği */
  }
  
  /* Kart adı, fırsat türü ve değeri için stil ayarları */
  .kart-adi, .firsat-turu, .firsat-degeri {
    font-size: 12px; /* Yazı boyutu */

  }
  
  .kart-adi {
    font-weight: bold; /* Kart adı kalın yazılmış */
    font-size: 12px !important; /* Yazı boyutu */
  }
  
  /* Sil butonu stil ayarları */
  .sil-butonu {
    font-size: 12px !important; /* Yazı boyutu */
    color: white; /* Yazı rengi */
    background-color: #d9534f; /* Arka plan rengi */
    border: none; /* Çerçeve kaldırıldı */
    border-radius: 0 0 5px 5px; /* Köşe yuvarlaklığı */
    cursor: pointer; /* İmleç buton üzerine geldiğinde el şeklini alır */
    align-self: flex-end; /* Butonu sağa yasla */

  }
  
  .sil-butonu:hover {
    background-color: #c9302c; /* Butonun üzerine gelindiğinde arka plan rengi */
  }
  .line{
    border-top: 1px solid #255588;
  }