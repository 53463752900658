.data-grid {
    border-collapse: collapse;
    width: 100%;
  }
  
  .data-grid th, .data-grid td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .data-grid th {
    background-color: #f2f2f2;
  }
  
  .context-menu {
    position: fixed; /* Menünün ekran üzerindeki konumunu sabitlemek için */
    background-color: white;
    border: 1px solid #ddd;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    list-style: none;
    margin: 0;
    z-index: 1000;
    min-width: 150px; /* Menü genişliği */
    max-width: 300px; /* Gerektiğinde menünün genişleyebilmesi için */
    overflow: hidden; /* Menü içeriği taştığında kesilmesi için */
  }
  .context-menu-icon {
    display: inline-block; /* İkonu inline bir element gibi göster */
    vertical-align: middle; /* İkonu metinle ortalanmış hale getir */
    margin-right: 8px; /* İkon ile metin arasında boşluk bırak */
    width: 24px; /* İkon genişliği */
    height: 24px; /* İkon yüksekliği */
    text-align: center; /* İkon içindeki içeriği ortala (SVG veya font ikonlar için) */
  }
  
  .context-menu li {
    padding: 8px 12px;
    cursor: pointer;
    white-space: nowrap; /* Menü öğelerinin tek satırda görünmesi için */
    display: flex; /* Flexbox ile öğeleri yatay olarak hizala */
    align-items: center; /* İkon ve metni dikey olarak ortala */
  }
  
  .context-menu li:hover {
    background-color: #f0f0f0;
  }
  