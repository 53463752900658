/* Tablo ve hücreler için genel stil ayarları */
.table-responsive {
  overflow-x: auto; /* Yatay kaydırma için */
}


.table th,
.table td {
  white-space: nowrap; /* Metnin bir satırda sığdırılması */
  overflow: hidden;
  text-overflow: ellipsis;
}

.table tr {
  height: 30px; /* Satır yüksekliğini sabitle */
}
th {
    position: relative; /* Sürükleme sapı için konum referansı */
  }
  
  /* Sürükleme sapı stilini gizli olarak başlat */
  .resize-handle {
    position: absolute;
    top: 0;
    right: 0;
    width: 5px; /* Sürükleme alanının genişliği */
    height: 100%;
    cursor: col-resize;
    background-color: transparent; /* Varsayılan olarak şeffaf */
    z-index: 10;
  }
  
  /* Sütun başlığının üzerine gelindiğinde sürükleme sapını göster */
  th:hover .resize-handle {
    background-color: #ccc; /* Sürükleme sapını belirginleştirmek için */
  }
/* Responsive tasarım için medya sorguları */
@media (max-width: 768px) {
  .table th,
  .table td {
    max-width: 100px; /* Küçük ekranlarda kolon genişliğini azalt */
  }
}
