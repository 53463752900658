.menu2 {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 5px 0;
    width: 120px;
    z-index: 1;
  }
  
  .menu2 button {
    display: block;
    width: 100%;
    padding: 8px 10px;
    text-align: left;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
  
  .menu2 button:hover {
    background-color: #f0f0f0;
  }
  
  .menu2 button:first-child {
    border-bottom: 1px solid #ccc;
  }
  