

.room-card {
    background-color: #f7f7f7;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
}

.room-card:hover {
    transform: scale(1.05);
}

.room-card h2 {
    font-size: 1.2em;
    margin-bottom: 0.5em;
}

.room-card p {
    margin: 0.5em 0;
}

.room-card.available {
    border-color: green;
}

.room-card.occupied {
    border-color: red;
}

.room-card.cleaning {
    border-color: orange;
}
