.infoPanelContainer {
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.267);
    background-color: #fff;
    border:1px solid #255588;
  }
  
  .infoPanelTitle {
    color: #333;
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
  }
  
  .infoPanel {
    display: flex;
    flex-direction: column;
  }
  
  .item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .icon {
    font-size: 24px;
    color: #4a90e2; /* Mavi tonunda bir renk */
    margin-right: 15px;
  }
  
  .title {
    margin: 0;
    color: #333;
    font-size: 20px;
  }
  
  .description {
    color: #666;
    font-size: 11px;
    font-family: "Roboto", sans-serif;
  }
  