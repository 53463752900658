.menuparent {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.menu1 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; // Bu değeri tarayıcı uyumluluğu için artırabilir veya azaltabilirsiniz.
  overflow-y: scroll;
  cursor: pointer;




  // Firefox için özelleştirme
  scrollbar-width: thin; // "auto" veya "thin" değerleri kullanılabilir
   scrollbar-color: #e02d39 #2c3e54; // İlk değer thumb için, ikinci değer track için
  // display: none;
}

/* Menü öğeleri */
.menu-item {
  padding: 3px 10px; /* Dolgulama */
  color: white; /* Yazı rengi */
  cursor: pointer; /* İmleç stilini değiştir */
  transition: background-color 0.3s ease; /* Arkaplan rengi geçişi */
  border-radius: 5px; /* Köşeleri yuvarla */
  // &:hover {
  //   background-color: #e0e0e0; /* Arkaplan rengi */
  //   color: #c0392b !important;
  //   font-weight: bold;
  // }
  span {
    margin-left: 10px; /* İkon ile metin arasındaki boşluk */
  }
}

.menu-item-1 {
  display: flex; /* Flexbox düzeni */
  align-items: center; /* Dikey hizalama */
  padding: 0 10px;
  &:hover {
    background-color: #e0e0e0; /* Arkaplan rengi */
    color: #c0392b !important;
    border-radius: 5px; /* Köşeleri yuvarla */
  }
}

/* Alt menüler */
.sub-menu {
  padding-left: 20px; /* Sol iç boşluk */
  position: relative; /* Pozisyon ayarı için gerekli */
}
.sub-menu::before {
  content: ""; /* İçerik gereksiz, bu yüzden boş */
  position: absolute; /* Pozisyonlandırma */
  left: 26px; /* Sol kenara hizalama */
  top: 0; /* Üst kenara hizalama */
  bottom: 0; /* Alt kenara hizalama */
  border-left: 1.5px solid #bdc3c7;
}

/* Açık alt menü */
.menu-chevron.open + .sub-menu {
  display: block; /* Göster */
}

/* Chevron (açılır simge) */
.menu-chevron {
  margin-left: auto; /* Sağa hizala */
  transition: transform 0.3s ease; /* Dönüşüm geçişi */
}

/* Açık chevron */
.menu-chevron.open {
  transform: rotate(180deg); /* 180 derece döndür */
}
/* Sidebar.css */

/* Toggle butonunun konumunu ve stili güncelleyin */
.sidebar-toggle {
  position: absolute;
  height: 30px;
  width: 40px;
  top: 45%; /* İstenilen yükseklikte konumlandır */
  left: 5px !important;
  z-index: 100; /* Diğer elementlerin üzerinde görünmesini sağla */
  cursor: pointer;
  transition: left 0.3s ease; /* Animasyon için */
}

/* Sidebar açıkken toggle butonunun konumunu ayarlayın */
.App-sidebar.open .sidebar-toggle {
  left: 16% !important; /* Sidebar genişliğine göre ayarlayın */
}


/* Tooltip için stil */
.tooltip-text {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 3px;
  left: 0; /* Konumu ayarlayın */
  margin-left: 30px; /* Genişliğe göre merkezleme */

  transition: visibility 0.3s, opacity 0.3s ease; /* Animasyon için */
  opacity: 0;
}

.sidebar-toggle:hover .tooltip-text {
  visibility: visible;
  opacity: 1; /* Tooltip'i göster */
}
/* Yeni toggle-icon div'leri için stil */
.rotate-icon,
.rotate-icon1 {
  width: 15px; /* Genişlik */
  height: 6px; /* Yükseklik */
  background-color: #bdc3c7; /* Renk */
  margin: 5px 0; /* Aralık */
  transition: transform 0.3s; /* Animasyon süresi */
  border-radius: 5px;
}
.rotate-icon {
  transform: rotate(90deg); /* 90 derece döndür */
}
.rotate-icon1 {
  transform: rotate(90deg); /* 90 derece döndür */
}
/* Rotasyon sınıfı */
.sidebar-toggle {
  &:hover {
    .open {
      .rotate-icon {
        transform: rotate(105deg); // 180 derece döndür
      }
      .rotate-icon1 {
        transform: rotate(75deg); // 270 derece döndür
      }
    }
  }
}
.sidebar-toggle {
  &:hover {
    .closed {
      .rotate-icon {
        transform: rotate(75deg); // 180 derece döndür
      }
      .rotate-icon1 {
        transform: rotate(105deg); // 270 derece döndür
      }
    }
  }
}
.sidebar-toggle {
  .closed {
    .rotate-icon {
      transform: rotate(75deg); // 180 derece döndür
    }
    .rotate-icon1 {
      transform: rotate(105deg); // 270 derece döndür
    }
  }
}

@media screen and (max-width: 1028px) {
  .App-sidebar.open .sidebar-toggle {
    left: 28% !important; /* Sidebar genişliğine göre ayarlayın */
  }
}
@media screen and (max-width: 768px) {
  .App-sidebar.open .sidebar-toggle {
    left: 62% !important; /* Sidebar genişliğine göre ayarlayın */
  }
}
@media screen and (max-width: 568px) {
  .App-sidebar.open .sidebar-toggle {
    left: 62% !important; /* Sidebar genişliğine göre ayarlayın */
  }
}
