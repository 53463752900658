@import url("https://fonts.googleapis.com/css2?family=Jost:wght@500&family=Raleway:wght@300&display=swap");
.jost {
  font-family: "Jost", sans-serif;
  font-size: 2.5rem;
  color: #3380d2;
}
.jost1 {
  font-family: "Jost", sans-serif;
  font-size: 2rem;
  color: #e12d39;
}
.App-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.2);
  .header-section {
    display: flex;
    align-items: center;
    justify-content: center; /* Her bölüm içindeki içerikleri merkeze alır */
    text-align: center;

    &.left {
      width: 15%;
    }

    &.center {
      width: 70%;
    }

    &.right {
      width: 15%;
    }
  }
  .header-section.right {
    display: flex; // Flexbox kullanımını etkinleştirir
    justify-content: flex-end; // İçerikleri sağa yaslar
    align-items: center; // İçerikleri dikey olarak ortalar
    width: 100%; // Sağ bölümün genişliğini tam olarak almasını sağlar
  }
  .header-section.left {
    display: flex; // Flexbox kullanımını etkinleştirir
    justify-content: flex-start; // İçerikleri sağa yaslar
    align-items: center; // İçerikleri dikey olarak ortalar
    width: 100%; // Sağ bölümün genişliğini tam olarak almasını sağlar
  }
  .header-section.center {
    flex: 2; /* Merkezi bölümü biraz daha geniş tutar */
    height: 50px;
  }
}
/*dropdown menu itemleri*/
.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
  color: white;
  padding: 6px;
  font-size: 12px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Arka plan rengi değişikliği için yumuşak geçiş */
}

.dropdown-content,
.submenu-content {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  transform: scaleY(0);
  transform-origin: bottom;
  transition: transform 0.5s ease; /* Yükseklik değişikliği için yumuşak geçiş */
  opacity: 0; /* Başlangıçta görünmez */
}

.dropdown-content {
  bottom: 100%; /* Menüyü düğmenin üstünde göster */
  left: 0;
  width: 100%;
}

.dropdown:hover .dropdown-content,
.submenu:hover .submenu-content {
  transform: scaleY(1);
  opacity: 1; /* Görünür yap */
}

.dropdown:hover .dropbtn {
}

.alink {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.alink:hover {
  background-color: #255588;
  color: white !important;
  font-weight: bold !important;
}
.selected-hotel {
  background-color: #255588;
  color: white !important;
  font-weight: bold !important;
}
.submenu {
  position: relative;
}

.submenu-content {
  bottom: 0; /* Alt menüyü üst menü öğesinin üstünde göster */
  left: 100%;
}
.logo {
  width: 30%; /* Logo genişliği */
  height: 30%; /* Yükseklik, orantıyı koruyacak şekilde otomatik ayarlanır */
  width: 142px; /* Logo genişliği */
  height: 100px; /* Yükseklik, orantıyı koruyacak şekilde otomatik ayarlanır */
  display: block; /* Block seviyesinde görüntüleme, yatay hizalamayı kolaylaştırır */
  // margin: auto auto; /* Logoyu yatay olarak ortalar */
  object-fit: contain; /* Resmin boyutları kutuya sığacak şekilde ayarlanır, böylece oranlar korunur ve tekrarlama olmaz */
  background-repeat: no-repeat; /* Arka plan resminin tekrarlanmasını önler */
  background-position: center; /* Arka plan resmini ortalar */
  background-size: cover; /* Arka planı kaplayacak şekilde boyutlandırır, böylece tamamen dolar ama oranlarını korur */
}

/*dropdown menu itemleri*/

// Header Gokhan'ın Eklediği Kodlar

.logoutButton {
  background-color: #e02d39 !important; // Butonun arkaplan rengini ayarlar
  border-color: #fff !important; // Eğer butonun çerçevesi varsa, çerçeve rengini de aynı renge ayarlar
  transition: background-color 0.3s ease; // Renk değişim animasyonu

  &:hover {
    background-color: #f04e58 !important; // Üzerine gelindiğinde arkaplan rengi
    border-color: #fff !important; // Üzerine gelindiğinde çerçeve rengi
  }
}

.user-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px; // Sağa doğru biraz boşluk ekler
  position: relative; // Pozisyon referansı
}

.profile-image {
  width: 60px; // Profil resminin genişliği
  height: 60px; // Profil resminin yüksekliği
  border-radius: 50%; // Yuvarlak profil resmi
  margin-bottom: 5px; // Ad ve soyadının altında biraz boşluk
  object-fit: cover; // Resmin kutuya tam sığması için
}

.user-name {
  color: white; // Ad ve soyadının rengi
  font-weight: bold; // Ad ve soyadının font kalınlığı
}
.icons-wrapper {
  // Tüm çocuklar için cursor pointer uygula
  & > * {
    cursor: pointer;
  }
  & > *:hover {
    scale: 1.1;
  }
  // İlk çocuğa özel hover stilini uygula
  & > *:nth-child(1):hover {
    color: #e02d39 !important;
  }

  // İkinci çocuğa özel hover stilini uygula
  & > *:nth-child(2):hover {
    color: #e02d39 !important;
  }

  // Üçüncü çocuğa özel hover stilini uygula
  & > *:nth-child(3):hover {
    color: #e02d39 !important;
  }
}

.dropdown-menu {
  position: absolute;
  top: 100%; // Kullanıcı profil div'inin hemen altında
  left: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 200px; // Menünün genişliği
  z-index: 1000; // Diğer içeriklerin üzerinde görünmesi için
}

.dropdown-menu .dropdown-item {
  padding: 8px 12px;
  cursor: pointer;
}

.dropdown-menu .dropdown-item:hover {
  background-color: #f8f8f8;
}

.dropdown-item {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

.user-name:hover {
  cursor: pointer;
  color: red !important; /* CSS dosyasında !important kullanmak genellikle tavsiye edilmez ama burada özelliği önceliklendirmek için kullanılıyor */
}

.icon-hover:hover {
  color: red !important; /* İkon rengini değiştir */
  transform: scale(1.1); /* İkonu büyüt */
}

.icon-close {
  margin-left: 3px; /* ya da istediğiniz değer */
}

.icon-white {
  color: white;
}
// Header Gokhan'ın Eklediği Kodlar
