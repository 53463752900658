.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #d5d5d5;
  }
  
  .login-title {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .login-input {
    width: 300px;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .login-button {
    width: 300px;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }