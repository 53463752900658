/* FAB.css */
.fab-container1 {
    padding: 10px;
    position: relative;
    z-index: 9999;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
  }
  
  .fab1 {
    border: none;
    border-radius: 15%;
    width: 36px;
    height: 36px;
    background-color: #255588;
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100; /* Butonun menünün üzerinde olmasını sağlar */
  }
  
  .fab1:hover {
    background-color: #e02d39;
  }
  
  .dropdown-menu1 {
    position: absolute;
    top: 100%; /* Butonun altında görünmesini sağlar */
    left: -152px;
    background-color: white;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    border-radius: 8px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    z-index: 1200; /* Diğer öğelerden daha yüksek bir z-index */
    width: 200px;
}
  
  .menu-item1 {
    padding: 10px;
    cursor: pointer;
    z-index: 1200;
  }
  
  .menu-item1:hover {
    background-color: #f0f0f0;
  }
  