.custom-form-container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .icon {
    margin-right: 5px;
  }
  
  .color-picker-container {
    max-width: 300px; /* Renk seçicinin maksimum genişliğini sınırlayın */
    margin: 0 auto; /* Merkeze alın */
  }
  
  /* SketchPicker'ın boyutunu ve padding'ini düzenleyin */
  .sketch-picker {
    box-sizing: border-box; /* Padding ve border boyutu genişliği etkilemesin diye */
    padding: 8px; /* İç padding */
  }
  
  /* Uygulama genelinde SketchPicker'ı etkilemek için */
  .react-color__sketch-picker {
    box-sizing: border-box; /* Padding ve border boyutu genişliği etkilemesin diye */
    padding: 8px; /* İç padding */
  }
  
  .color-code {
    text-align: center;
    margin-top: 10px;
    font-weight: bold;
    color: #333;
  }
  
  /* Responsive tasarım için Media Query */
  @media (max-width: 768px) {
    .custom-form-container {
      padding: 10px;
    }
  }
  
  