.kolay-erisim {
    display: flex;
    align-items: center;
    overflow: hidden;
    max-height: 20px;
    .scroll-button {
      border: none;
      background-color: transparent;
      cursor: pointer;
      font-size: 24px;
  
      &:focus {
        outline: none;
      }
    }
  
    .menu-container {
      overflow-x: scroll;
      scroll-behavior: smooth;
  
      // Scrollbar'ı gizle
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
  
    .menu {
      display: flex;
      padding: 10px 0;
      width: 50vw !important;
    }
  
    .menu-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 3px;
      text-align: center;
      position: relative;
  
      .icon-round {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #f2f2f2;
        margin-bottom: 8px;
  
        // İkonun boyutunu ayarlayın
        font-size: 30px;
      }

  
      .menu-title {
        font-size: 14px;
      }
      .delete-icon {
        position: absolute;
        bottom: 22px;
        right: 1px;
        cursor: pointer;
        z-index: 1000;
      }
      .delete-icon:hover svg {
        // position: absolute;
        // bottom: 22px;
        // right: 1px;
        // cursor: pointer;
        // z-index: 1000;
        color: red !important;
      }
    }

    .scroll-button:hover svg{
      color: #e02d39  !important;
      scale: 1.1;
    }
    
  }
.menu-item1 {
  padding: 1px; /* İçerik arası boşluk ve tıklama alanını genişletmek için */
  cursor: pointer; /* Üzerine gelindiğinde el işareti */
  border: 1px solid #ddd; /* Hafif bir çerçeve */
}

